<template>

    <div class="h-full overflow-hidden relative">

        <div class="absolute bg-box-80 z-10 h-full w-full top-0 rounded-lg left-0" v-if="loading">
            <loader :loading="loading" />
        </div>

        <div class="scroll-gradient"></div>

        <transition-group name="fade" tag="div" class="h-full overflow-y-auto">

            <div v-if="!loading && sl && sl.length === 0" class="h-full w-full flex flex-col justify-center items-center">
                <span class="module-title text-font-gray">No data in this section</span>
            </div>

            <div v-else class="box mb-2" :key='removeCharacters(i.id)' v-for="i in slSorted">

                <div class="flex items-center mb-4">
                    <div class="w-1 rounded-full h-6 mr-2"
                         :style="'background-color:' + legendColors[i.id].color + ';'">
                    </div>
                    <div class="text-2xl">{{ i.name }}</div>
                    <div class="h-8 w-8 rounded-lg flex flex-col justify-center items-center ml-auto cursor-pointer" :class="{'bg-aux-15 text-aux': slSelectedItems.includes(i.id), 'bg-filters-80 text-font-gray': !slSelectedItems.includes(i.id)}"
                             @click="toggleSlSelectedItems(i.id)">
                            <i class="mdi mdi-eye-outline" v-if="slSelectedItems.includes(i.id)"></i>
                            <i class="mdi mdi-eye-off-outline" v-else></i>
                        </div>
                </div>

                <div class="flex flex-col">

                    <div class="h-auto w-full flex flex-row justify-start items-center mb-4">

                        <span class="text-tiny text-font-gray mr-2">ANSR</span>
                        <span class="mr-4 text-xl font-semibold">{{ i.ner | reduceBigNumbers(2) }}€</span>

                        <span v-if="i.TNR != 'N/A'" class="text-tiny text-font-gray mr-2 ml-auto">TNR</span>
                        <span v-if="i.TNR != 'N/A'" class="mr-4 text-xl font-semibold">{{ i.TNR | reduceBigNumbers(2) }}€</span>

                        <span class="text-tiny text-font-gray ml-auto mr-2">Margin</span>
                        <span class="mr-4 text-xl font-semibold">{{ i.margin | reduceBigNumbers(2) }}%</span>

                    </div>

                    <div class="h-auto flex w-full">

                        <div class="w-1/2 pr-4">
                            <div class="flex items-center h-6 mb-4">
                                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                                    <span class="text-font-dark text-sm">vs Obj</span>
                                    <span class="font-semibold mt-1"><span v-if="i.ner_vs_objective>0">+</span>{{ i.ner_vs_objective || 0 | numberFormat(2) }}<span v-if=" i.ner_vs_objective!='TBD'">%</span></span>
                                </div>
                                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                                    <span class="text-font-dark text-sm">Unbilled</span>
                                    <span class="font-semibold mt-1">{{ i.unbilled || 0 | reduceBigNumbers(2) }}€</span>
                                </div>
                                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                                    <span class="text-font-dark text-sm">PMC</span>
                                    <span class="font-semibold mt-1">{{ i.pm_payment || 0 | reduceBigNumbers(0) }}d</span>
                                </div>
                            </div>
                            <div class="rounded-full h-2 bg-progress overflow-hidden relative">
                                <div class="rounded-full bg-blue h-full absolute left-0"
                                    :style="'width:' + i.avg_sale + '%;'">
                                </div>
                                <div class="rounded-full bg-success h-full absolute left-0" v-if="i.ner_vs_objective!='TBD'"
                                    :style="'width:' + i.ner_vs_objective + '%;'">
                                </div>
                            <div class="rounded-full bg-success h-full absolute left-0" v-else
                                :style="'width:100%;'">
                            </div>
                            </div>
                        </div>

                        <div class="w-1/2 px-4">
                            
                            <div class="flex justify-center items-center h-6 mb-4">
                                <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                    <span class="text-font-dark text-sm">vs Obj</span>
                                    <span class="font-semibold mt-1"><span v-if="i.margin_vs_objective > 0">+</span>{{ i.margin_vs_objective || 0 | numberFormat(2) }}<span v-if=" i.margin_vs_objective!='TBD'"> ptos.</span></span>
                                </div>
                                <!-- <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                    <span class="text-font-dark text-sm">vs Plan</span>
                                    <span class="font-semibold mt-1"><span v-if="i.vs_plan < 0">-</span><span v-if="i.vs_plan > 0">+</span>{{ i.vs_plan || 0 | reduceBigNumbers(2) }}</span>
                                </div> -->
                            </div>
                            <div class="rounded-full h-2 bg-progress flex overflow-hidden">
                                <div class="rounded-full bg-warning h-full" v-if="i.margin_vs_objective!='TBD'"
                                    :style="'width:' + i.margin_vs_objective + '%;'">
                                </div>
                            <div class="rounded-full bg-warning h-full" v-else
                                :style="'width:100%;'">
                            </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            
        </transition-group>

    </div>

</template>

<script>

    import { state, actions } from '@/store';
    export default {
        props: ['sortBy', 'sortOrder'],
        data() {
            return {
                sl: null,
                loading: false,
            }
        },
        computed: {
            params() { return state.globalParams },
            legendColors() { return state.slLegendColors },
            slSelectedItems() { return state.slSelectedItems },
            slSorted() {
                return this._.orderBy(this.sl , [this.sortBy.value], [this.sortOrder])
            }
        },
        watch: {
            params(n, o) { this.getSl() }
        },
        methods: {
            getSl() {
                actions.clearSlSelectedItems()
                this.$emit('loadingSl', true)
                this.loading = true
                this.axios.get('projects_management/ner_sl', {params: this.params}).then(response => {
                    this.sl = response.data.data
                    this.$setColorsBySector(this.sl)
                    this.loading = false
                    state.slSelectedItems = []
                    this.$emit('loadingSl', false)
                })
            },
            toggleSlSelectedItems(item) { actions.toggleSlSelectedItems(item) },
            removeCharacters(str) { return str.replace(/[^A-Z0-9]+/ig, "_") },
        },
        mounted() { this.getSl() }
    }

</script>